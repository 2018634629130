<template>
  <div id="vm-cola-soco-points" class="vm-cola-soco-points">
    <div class="soco-points-container">
      <h1>Scan & tukar Point</h1>
      <p class="description">
        Scan menggunakan SOCO app & <br />
        tukarkan point untuk belanja!
      </p>
      <div class="qrcode">
        <qrcode-vue :value="QRcode" :size="355" level="H"></qrcode-vue>
      </div>
    </div>

    <p class="cancel">Tidak jadi menukarkan Point?</p>
    <div class="back" @click="goBackToShoppingBag">Batalkan</div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import SocketIO from 'socket.io-client';
import helpers from '@/mixins/helpers';

const Config = require('~/config/default.env').default;
const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'vmColaSocoPoints',
  components: {
    QrcodeVue,
  },
  mixins: [helpers],

  data() {
    return {};
  },

  computed: {
    QRcode() {
      const QRCodeValue = this.$store.state.QRcode.value || '';
      return QRCodeValue;

      // if (this.is_development) {
      //   return 'https://sociolla.test-app.link?action=login&value=' + QRCodeValue;
      // }

      // return 'https://sociolla.app.link?action=login&value=' + QRCodeValue;
    },
    is_development() {
      return Config.NODE_ENV === 'development';
    },
    user() {
      return this.$store.state.QRcode.user;
    },
    user_points() {
      return (this.user && this.user.points && this.user.points.count) || 0;
    },
    user_point_list() {
      return this.$store.state.auth.userPointList || [];
    },
    selected_point() {
      return this.$route.query.point || 0;
    },
    cart_id() {
      return this.$route.query.cart_id || null;
    },
  },

  created() {
    socket.on('qr_code_login', async (data) => {
      if (this.selected_point && this.cart_id) {
        this.$store.commit('SET_GLOBAL_LOADING', true);
        try {
          const res = await this.$store.dispatch('applyPoints', {
            token: data.token,
            point: this.selected_point,
            context: this,
          });

          if (res) {
            setTimeout(() => {
              this.$store.commit('SET_GLOBAL_LOADING', false);
              this.goBackToShoppingBag();
            }, 2000);
          } else {
            this.$store.commit('SET_GLOBAL_LOADING', false);
          }
        } catch (err) {
          console.log('Error apply point => ', err);
        }
      }
    });
  },

  methods: {
    goBackToShoppingBag() {
      this.$router.push('/vmcola/order');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'soco-points';
</style>
